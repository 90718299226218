import { SoboAction, SoboState } from "../soboInterfaces";



const initialState: SoboState = {
    drawerOpen: false,
    showExitDialog: false,
    searchTerm: '',
    page: 1,
    keyword: null,
    selectedSoboAccount: null
};

/**
 * Standard reducer function for state management
 * @param state State of the component
 * @param action Action to dispatch
 */
function soboReducer(state: SoboState, action: SoboAction): SoboState {
    switch (action.type) {
        case 'SET_PAGE': {
            return {
                ...state,
                page: action.payload,
            };
        }
        case 'CHANGE_KEYWORD': {
            return {
                ...state,
                keyword: action.payload,
                page: 1
            }
        }
        case 'SET_DRAWER_OPEN': {
            return {
                ...state,
                drawerOpen: action.payload,
                page: 1,
            };
        }
        case 'EXIT_SOBO': {
            return {
                ...state,
                ...action.payload
            }
        }

        case 'EXIT_SOBO_CONFIRM': {
            return {
                ...state,
                selectedSoboAccount: null,
                showExitDialog: false
            }
        }
        case 'EXIT_SOBO_CANCEL': {
            return {
                ...state,
                showExitDialog: false
            }
        }
        case 'SET_SELECTED_ACCOUNT': {
            return {
                ...state,
                selectedSoboAccount: action.payload
            }
        }
        default:
            return state;
    }
}






export { soboReducer, initialState}