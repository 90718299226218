import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
  },
}));
interface Props {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="lg">
      <>{children}</>
    </Container>
  );
};

export default DefaultLayout;
