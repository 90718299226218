import { gql, useMutation } from '@apollo/client';
import * as logoutTypes from './__generated__/logout';

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const useLogout = () => {
  const [mutate, { data, error }] = useMutation<logoutTypes.logout>(LOGOUT);

  return { mutate, data, error };
};
