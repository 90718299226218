import { gql } from '@apollo/client';

export const productFragment = gql`
  fragment ProductFragment on Product {
    name
    seoName
    id
    listPrice
    price
    isDimensionedProduct
    primaryVariantOptions {
      id
      name
      value
      cdnURL
    }
    variants {
      Attributes {
        ValueId
        Value
        Name
      }
    }
    img {
      id
      cdnURL
      alt
    }
  }
`;
