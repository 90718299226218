import { gql } from '@apollo/client';

export const cartLinesFragment = gql`
  fragment CartLinesFragment on CartLine {
    id
    productId
    code
    available
    quantity
    amount
    name
    listPrice
    img {
      id
      alt
      cdnURL
    }
    selectedDimensions {
      id
      name
      value
    }
  }
`;
