import { gql, useMutation } from '@apollo/client';
import * as updateCartTypes from './__generated__/updateCart';

export const UPDATE_CART = gql`
  mutation updateCart($input: UpdateCartInput) {
    updateCart(input: $input) {
      email
    }
  }
`;

export const useUpdateCart = () => {
  const [mutate, { data, error }] = useMutation<
    updateCartTypes.updateCart,
    updateCartTypes.updateCartVariables
  >(UPDATE_CART);

  return { mutate, data, error };
};
