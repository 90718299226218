import {gql} from "@apollo/client";


export const FETCH_SESSION = gql`
  query fetchSession {
    session {
      cartId
      isImpersonated
      soboUser {
        email
        firstName
        lastName
        accountId
        contactId
        email
      }
    }
  }
`;

export interface SessionData {
    session: Session
}

export interface Session {
    cartId: string | number
    isImpersonated: string | boolean
    soboUser: SoboUser
}

export interface SoboUser {
    accountId: string | number
    contactId: string | number
    email: string
    firstName: string
    lastName: string
    aspNetUserId: number
}
